/**
 * Import Dependency
 */
import { logoBaseUrl } from './../../config'
import localforage from 'localforage'

/**
 * Import API
 */
import locationAPI from '../../services/api/modules/location'

/**
 * Declare Variable
 */
const state = {
  id: localStorage.getItem('locationId') || 0,
  name: localStorage.getItem('locationName') || '',
  logo: localStorage.getItem('locationLogo') || '',
  ready: false,
  locations: []
}

const getters = {
  getLocationId: state => state.id,
  getName: state => state.name,
  getLogo: state => state.logo,
  getLocations: state => state.locations,
  getLocationCount: state => state.locations.length
}

const actions = {
  /**
   * Check if we have all necessary location info. Load from the S2M API if necessary
   *
   * @param context
   */
  initLocation: context => {
    // We're good to go if we got a name and logo
    if (context.state.name !== '' && context.state.logo !== '') {
      context.commit('location_ready')
      return
    }

    // Otherwise we need to get the name and logo from the server
    context.dispatch('updateLocation').then(() => {
      context.commit('location_ready')
    })
  },

  /**
   * Get all available S2M locations
   *
   * @param context
   */
  getAllLocations: context => {
    // No need to load the list twice
    if (context.state.locations.length !== 0) {
      return
    }

    // let locations = localforage.getItem('locations').then(value => {
    //   if (value !== null) {
    //     context.commit('location_locationlist', { locations: value })
    //     context.commit('stats_set_locations', { count: value.length })
    //   }
    // })

    locationAPI
      .getAllLocationsData()
      .then(res => {
        if (res.data.Results.length > 0) {
          localforage.setItem('locations', res.data.Results)
          context.commit('location_locationlist', { locations: res.data.Results })

          context.commit('stats_set_locations', { count: res.data.Results.length })
        }
      })
      .catch(error => console.log(error))
  },

  /**
   * Change the location id and init loading the location data
   *
   * @param context
   * @param locationId
   */
  changeLocation: (context, { locationId }) => {
    context.commit('location_change_id', { locationId })
    context.dispatch('initLocation')
  },

  /**
   * Update the location name and logo using the S2M API
   *
   * @param context
   * @return {Promise<[any , any]>}
   */
  updateLocation: context => {
    const loadData = locationAPI
      .getLocationData({ id: context.state.id })
      .then(res => {
        context.commit('location_change_name', { name: res.data.Name })
        return res
      })
      .catch(error => console.log(error))

    const loadLogo = locationAPI
      .getLocationLogo({ id: context.state.id })
      .then(res => {
        context.commit('location_change_logo', { logo: res.data.UrlOriginal })
        return res
      })
      .catch(error => console.log(error))

    return Promise.all([loadData, loadLogo])
  }
}

const mutations = {
  /**
   * Indicate that all required location information is available
   *
   * @param state
   */
  location_ready: state => {
    state.ready = true
  },

  /**
   * Set the list of all available S2M locations
   *
   * @param state
   * @param locations
   */
  location_locationlist: (state, { locations }) => {
    state.locations = locations
  },

  /**
   * Change the location id and clear the previous location name and logo
   *
   * @param state
   * @param locationId
   */
  location_change_id: (state, { locationId }) => {
    state.id = locationId
    state.name = ''
    state.logo = ''
    localStorage.setItem('locationId', locationId)
  },
  
  /**
   * Change the location logo. Also update the localStorage
   *
   * @param state
   * @param logo
   */
  location_change_logo: (state, { logo }) => {
    // logo = logoBaseUrl + state.id + '/' + logo
    localStorage.setItem('locationLogo', logo)
    state.logo = logo
  },
  /**
   * Change the location name. Also update the localStorage
   *
   * @param state
   * @param name
   */
  location_change_name: (state, { name }) => {
    localStorage.setItem('locationName', name)
    state.name = name
  }
}

/**
 * Export
 */
export default {
  state,
  getters,
  actions,
  mutations
}
